// Here you can add other styles
.flex-1 {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
}
.breadcrumb-item.active {
    color: rgb(9, 9, 161);
    font-weight: 400;
    text-transform: capitalize;
}
.c-sidebar {
    background: #211d1e;
    .brand-logo {
        background-color: #211d1e;
    }
    .c-sidebar-nav-link,
    .c-sidebar-nav-dropdown-toggle {
        &:hover {
            color: $white;
            background: rgb(103, 103, 114);
            // border-radius: 30px 0 30px 0;
        }
        &.c-active {
            border-radius: 20px 0 20px 0;
            background: rgba($themeColor, 0.8);
            &.c-sidebar-nav-dropdown-toggle {
                color: $white;
                background: rgba($themeColor, 0.05);
            }
        }
    }
    .c-sidebar-nav-dropdown-items {
        background-color: #27303a;
    }
}
a {
    color: $themeColor;
    &:hover {
        text-decoration: none;
        color: $themeColor;
    }
}
.card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.c-body {
    background-color: #f3eef7;
}
.center-block {
    position: relative;
    z-index: 9;
    width: 150px;
    height: 0;
    margin: 0 auto;
}
.center-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    // padding: 15px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translate(-50%, -50%);
    img {
        margin-bottom: 20px;
        width: 100%;
        height: 100%;
    }
}
.column {
    padding-left: 15px;
    padding-right: 15px;
}
.row {
    &.padding-b20 {
        [class^="col-"] {
            padding-bottom: 20px;
        }
    }
    &.space-8 {
        margin-left: -8px;
        margin-right: -8px;
        .column {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}
.title {
    margin-bottom: 20px;
    color: blue;
}
.clear-btn {
    margin-top: 30px;
}
.text-theme {
    color: $themeColor;
}
.c-footer {
    background-color: $white;
}
.pt-6 {
    padding-top: 60px !important;
}
.pt-9 {
    padding-top: 90px !important;
}
.pb-9 {
    padding-bottom: 90px !important;
}
.registration-list {
    & > ul {
        padding-left: 0;
        margin-bottom: 0;
        & > li {
            border: 1px solid $color2;
            border-bottom: 0;
            &:last-child {
                border-bottom: 1px solid $color2;
            }
        }
        .title {
            width: 40%;
            border-right: 1px solid $color2;
        }
        .data {
            width: 60%;
        }
        .data,
        .title {
            padding: 8px 12px;
        }
    }
}
.ql-container {
    &.ql-snow {
        min-height: 350px;
    }
}
.react-bootstrap-table {
    width: 100%;
    overflow: auto;
    .table {
        min-width: 900px;
    }
}
.md {
    .react-bootstrap-table {
        .table {
            min-width: 700px;
        }
    }
}
.sm {
    .react-bootstrap-table {
        .table {
            min-width: 550px;
        }
    }
}
.btn-primary {
    color: #ffffff !important;
}
.text-ellipsis {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.form-block {
    position: relative;
    .btn-icon {
        position: absolute;
        right: 0;
        top: 32px;
    }
}
.row {
    &.padding-b10 {
        [class^="col-"] {
            padding-bottom: 10px;
        }
    }
}
@include responsive(md) {
    .pt-9 {
        padding-top: 70px !important;
    }
    .pb-9 {
        padding-bottom: 70px !important;
    }
    .center-logo {
        width: 100px;
        height: 100px;
    }
}
#question_dialog_box button.rrt-button.rrt-ok-btn.toastr-control {
    background-color: #2e965c !important ;
    font-weight: bolder;
    font-size: large;
    color: white !important;
}
#question_dialog_box button.rrt-button.rrt-cancel-btn.toastr-control {
    background-color: #d93832 !important ;
    font-weight: bolder;
    font-size: large;
    color: white !important;
}
.loader-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: 50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.loader1 {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #414042; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.upload-input {
    border: 2px dashed #ccc;
    border-radius: 5px;
    width: 130px;
    height: 130px;
    display: grid;
    place-items: center;
    margin: 10px;
}
.image-item {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 130px;
    height: 130px;
    position: relative;
    border-radius: 5px;
    margin: 10px;
    border: 2px dashed #ccc;
    img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        // object-fit: cover;
    }
    .upload-remove {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 5px;
        background-color: #f20202;
        color: $white;
        border-radius: 10px;
        display: grid;
        place-items: center;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
}

.theme-button-green {
    background-color: #405a8b !important;
    border: 0px solid #405a8b !important;
}
.btn-left {
    margin-left: 2%;
}
.button-curser {
    cursor: no-drop !important;
}

.btn-dark-red {
    background-color: #5c6668 !important;
    border: 0px solid #5c4c4c !important;
}

.login-card {
    // box-shadow: 7px 7px #405a8b;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
        rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.page-item.active .page-link {
    background-color: $themeColor !important;
    border-color: $themeColor !important;
}
.preview-img {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.my-component {
    background: #fff;
}

.fullscreen-enabled .my-component {
    background: #000;
}

.hide {
    img {
        display: none;
    }
}

.card-form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #e5f1fa;
    color: black;
    background-color: #ffffff;
    border-color: #485966;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.action-img {
    cursor: pointer;
}
.react-datepicker-wrapper {
    width: 100%;
}

.tooltip-container {
    height: 100px;
    width: 250px;
}

@include responsive(md) {
    .tooltip-container {
        height: 100px;
        width: 150px;
    }
}
@include responsive(sm) {
    .tooltip-container {
        height: 100px;
        width: 150px;
    }
}
.btn-dark {
    background-color: $themeColor !important;
}

.custom-button {
    border-color: $themeColor;
    color: #fff;
    font-weight: 500;
}
.custom-button:hover {
    box-shadow: 0 0 5px 0 $themeColor inset, 0 0 5px 2px $themeColor;
}
// .custom-button {
//     border-color: $themeColor;
//     color: $themeColor;
//     font-weight: 400;
//     background-image: -webkit-linear-gradient(45deg, $themeColor 50%, transparent 50%);
//     background-image: linear-gradient(45deg, $themeColor 50%, transparent 50%);
//     background-position: 100%;
//     background-size: 400%;
//     -webkit-transition: background 500ms ease-in-out;
//     transition: background 500ms ease-in-out;
// }
// .custom-button:hover {
//     color: white;
//     font-weight: 400;
//     background-position: 0;
// }
.action-btn-shadow:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.modal-dialog {
    overflow: hidden;
}
.modal-header {
    background-color: $themeColor;
    color: #fff;
}
.textbox {
    width: 100%;
    min-height: 100px;
    height: 100%;
    resize: none;
}
// for dashboard gradients
.bg-gradient-green {
    background: #aa1471 !important;
    background: linear-gradient(45deg, #aa1471 0%, #922684 100%) !important;
    border-color: #aa1471 !important;
}
.bg-gradient-purple {
    background: #a414cf !important;
    background: linear-gradient(45deg, #a414cf 0%, #4b005e 100%) !important;
    border-color: #a414cf !important;
}
.rdrDateDisplay {
    display: none !important;
}
.btn-top {
    margin-top: 30px;
}
.custom-modal {
    .modal-dialog {
        max-width: 60% !important;
    }
}

.card-body-scroll {
    max-height: 72vh;
    overflow-y: scroll;
    // -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
}
.profile-label {
    margin: left 10px;
}
.leaflet-bottom {
    display: none;
    bottom: 0;
}
.rdrInputRanges{
    display: none;
}
.chart-scroll { overflow-x: scroll; }
.chart-width { width: 1200px; }
.Toastify__toast--info{
    background-color: #414042 !important;
    color: white !important;
}
.toast-text{
    color: rgb(255, 255, 255) !important;
}
.font-small{
    font-size: 12px;
}
.pointer-event-none{
    pointer-events: none;
}
 /* width */
 ::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(58, 57, 57);
  }  
  